<template>
    <div class="container-fuild nf404">
        <div class="glitch-effect">ERROR 404 : NOT FOUND
            <div class="glitch-text">
                <div class="glitch-text-1">ERROR 404 : NOT FOUND</div>
            </div>
            <div class="glitch-text">
                <div class="glitch-text-2">ERROR 404 : NOT FOUND</div>
            </div>
        </div>
    </div>
</template>